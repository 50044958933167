<template>
  <div class="agree-box mt-80">
    <div class="all-check">
      <label>
        <input type="checkbox" v-model="allCheck" @click="checkAll(!allCheck)">
        <span class="input-icon"></span>전체동의
      </label>
    </div>
    <ul class="check-list label-inline fs-13">
      <li class="d-flex ">
        <label>
          <input type="checkbox" v-model="essential1" @change="selected">
          <span class="input-icon"></span>
          <span class="color-1">(필수)&nbsp;</span>이용약관
        </label>
        <a href="/policy/1" target="_blank" rel="noopener noreferrer" class="agree-link">[자세히보기]</a>
      </li>
      <li class="d-flex ">
        <label>
          <input type="checkbox" v-model="essential2" @change="selected">
          <span class="input-icon"></span>
          <span class="color-1">(필수)&nbsp;</span>개인정보 수집 및 이용동의
        </label>
        <a href="/policy/3" target="_blank" rel="noopener noreferrer" class="agree-link">[자세히보기]</a>
      </li>
      <li class="d-flex ">
        <label>
          <input type="checkbox" v-model="essential3" @change="selected">
          <span class="input-icon"></span>
          <span class="color-1">(필수)&nbsp;</span>개인정보 제3자 제공동의
        </label>
        <a href="/policy/4" target="_blank" rel="noopener noreferrer" class="agree-link">[자세히보기]</a>
      </li>
      <li class="d-flex ">
        <label>
          <input type="checkbox" v-model="essential4" @change="selected">
          <span class="input-icon"></span>
          <span class="color-1">(필수)&nbsp;</span>만 14세 이상입니다.
        </label>
      </li>
      <li class="d-flex ">
        <label>
          <input type="checkbox" v-model="optional1" @change="selected">
          <span class="input-icon"></span>
          <span class="color-1">(선택)&nbsp;</span>마케팅, 서비스안내 수신
        </label>
      </li>
    </ul>

    <div v-if="isShowMsg" :class="'error-message'">{{ $msg('required.agree') }}</div>
  </div>
</template>

<script>
export default {
  name: "agree",
  data() {
    return {
      allCheck: false,
      essential1: false, // 이용약관
      essential2: false, // 개인정보 수집 및 이용동의
      essential3: false, // 개인정보 제3자 제공동의
      essential4: false, // 만 14세 이상
      optional1: false,  // 마케팅, 서비스안내 수신
      isShowMsg: false,
    }
  },
  watch: {
  },
  methods: {
    checkAll(is) {
      this.allCheck = is
      this.essential1 = is
      this.essential2 = is
      this.essential3 = is
      this.essential4 = is
      this.optional1 = is
    },
    selected () {
      this.allCheck = this.essential1 && this.essential2 && this.essential3 && this.essential4 && this.optional1
    },
    chkAgree() {
      if (this.essential1 && this.essential2 && this.essential3 && this.essential4) {
        this.isShowMsg = false
        return true
      }
      else {
        this.isShowMsg = true
        return false
      }
    },
  },
}
</script>
